// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #000000;
  padding: 10px;
  text-align: center;
  color: white;
  width: 100%; /* Asegura que ocupe todo el ancho */
  position: relative; /* Cambia a relative si no deseas que esté fijo */
  bottom: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,WAAW,EAAE,oCAAoC;EACjD,kBAAkB,EAAE,iDAAiD;EACrE,SAAS;EACT,OAAO;AACT","sourcesContent":[".footer {\n  background-color: #000000;\n  padding: 10px;\n  text-align: center;\n  color: white;\n  width: 100%; /* Asegura que ocupe todo el ancho */\n  position: relative; /* Cambia a relative si no deseas que esté fijo */\n  bottom: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
