// src/utils/formatCurrency.js

/**
 * Formatea un valor numérico como una cadena de moneda con un prefijo específico.
 * @param {number|string} value - El valor a formatear.
 * @param {number} decimalPlaces - Número de decimales.
 * @param {string} prefix - Prefijo de la moneda.
 * @returns {string} Valor formateado como cadena.
 */
export const formatCurrency = (value, decimalPlaces = 3, prefix = 'U$S ') => {
  console.log('formatCurrency - Valor recibido:', value);

  if (value === null || value === undefined) {
    console.log('formatCurrency - Valor es null o undefined');
    return 'N/A';
  }

  if (typeof value === 'string' && value.includes(prefix)) {
    console.log('formatCurrency - Valor ya está formateado');
    return value;
  }

  const numericValue = parseFloat(value);
  console.log('formatCurrency - Valor numérico:', numericValue);

  if (isNaN(numericValue)) {
    console.log('formatCurrency - Valor no es un número válido');
    return 'N/A';
  }

  const formattedValue = `${prefix}${new Intl.NumberFormat('es-ES', {
    style: 'decimal',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(numericValue)}`;

  console.log('formatCurrency - Valor formateado:', formattedValue);
  return formattedValue;
};

/**
 * Formatea un valor numérico con dos decimales.
 * @param {number|string} value - El valor a formatear.
 * @returns {string} Valor formateado como cadena.
 */
export const formatNumberTwoDecimals = (value) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) {
    return 'N/A';
  }

  return numericValue.toFixed(2);
};

/**
 * Formatea un valor numérico como porcentaje con dos decimales.
 * @param {number|string} value - El valor a formatear.
 * @returns {string} Valor formateado como cadena.
 */
export const formatPercentage = (value) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  if (typeof value === 'string' && value.includes('%')) {
    return value;
  }

  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) {
    return 'N/A';
  }

  return `${numericValue.toFixed(2)}%`;
};
