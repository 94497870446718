// src/App.js

import React, { useEffect, useState } from 'react';
import styles from './App.module.css';
import logoPath from './assets/img/logo.png';
import logoBWPath from './assets/img/logobw.png';
import Form from './components/Form';
import ProcesoDatos from './components/ProcesoDatos';
import Result from './components/Result';
import Footer from './components/Footer';
import AdditionalForm from './components/AdditionalForm';
import generatePDF from './components/ResultPDF';

const App = () => {
  const [result, setResult] = useState(null);
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const [analysis, setAnalysis] = useState({
    antecedentes: [],
    valores_venta: [],
    bien_problema: {},
    antecedentes_inverse: [],
    K: [],
    error: 'No hay datos disponibles',
    coeficientes_ajuste: {
      C1: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
      C2: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
      C3: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
      C4: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null }
    },
    valores_ajustados: { B1: null, B2: null, B3: null, B4: null },
    valor_estimado: null,
    desviacion_estandar: '',
    incertidumbre: '',
    valor_minimo: null,
    valor_maximo: null
  });
  const [logoBWBase64, setLogoBWBase64] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar la carga
  const [loadingStep, setLoadingStep] = useState(''); // Estado para los mensajes de carga
  const [formData, setFormData] = useState(null); // Nuevo estado para almacenar los datos del formulario

  // Función para convertir un archivo a Base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Cargar el logo en formato Base64 al montar el componente
  useEffect(() => {
    fetch(logoBWPath)
      .then(res => res.blob())
      .then(blob => getBase64(blob))
      .then(base64 => setLogoBWBase64(base64))
      .catch(err => console.error(err));
  }, []);

  /**
   * Maneja el envío del formulario inicial de tasación.
   *
   * @param {Object} data - Datos del formulario de tasación.
   */
  const handleSubmit = async (data) => {
    setFormData(data); // Almacenar los datos del formulario en el estado
    setIsLoading(true); // Iniciar el estado de carga
    setLoadingStep('Recibiendo la información...'); // Primer paso

    // Simular los diferentes pasos de carga con retrasos
    setTimeout(() => {
      setLoadingStep('Procesando la información...'); // Segundo paso

      setTimeout(async () => {
        setLoadingStep('Realizando cálculos...'); // Tercer paso

        setTimeout(async () => {
          // Actualizar el estado de análisis con los datos del formulario
          setAnalysis(prevState => ({
            ...prevState,
            antecedentes: data.antecedentes,
            valores_venta: data.valoresVenta,
            bien_problema: data.bienProblema
          }));

          try {
            const response = await fetch("https://backend-tasacion.herokuapp.com/api/tasacion", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Respuesta completa de la API:', responseData);

            const resultado = responseData.resultado || {};
            const antecedentes_inverse = resultado.antecedentes_inverse || [];
            const K = resultado.K || [];
            const coeficientes_ajuste = resultado.coeficientes_ajuste || {
              C1: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
              C2: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
              C3: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
              C4: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null }
            };
            const valores_ajustados = resultado.valores_ajustados || { B1: null, B2: null, B3: null, B4: null };
            const valor_estimado_str = resultado.valor_estimado || '';
            const desviacion_estandar = resultado.desviacion_estandar || '';
            const incertidumbre = resultado.incertidumbre || '';
            const valor_minimo_str = resultado.valor_minimo || '';
            const valor_maximo_str = resultado.valor_maximo || '';

            // Función para limpiar y convertir a número
            const parseCurrency = (value) => {
              if (typeof value === 'string') {
                let cleanedValue = value.replace(/[^0-9.,-]+/g, '');
                cleanedValue = cleanedValue.replace(',', '.');
                return parseFloat(cleanedValue);
              }
              return value;
            };

            const valor_estimado = parseCurrency(valor_estimado_str);
            const valor_minimo = parseCurrency(valor_minimo_str);
            const valor_maximo = parseCurrency(valor_maximo_str);

            console.log('Análisis:', antecedentes_inverse, K, coeficientes_ajuste, valores_ajustados);

            setResult(resultado);
            setAnalysis(prevState => ({
              ...prevState,
              antecedentes_inverse: antecedentes_inverse,
              K: K,
              error: responseData.error || null,
              coeficientes_ajuste: coeficientes_ajuste,
              valores_ajustados: valores_ajustados,
              valor_estimado: valor_estimado,
              desviacion_estandar: desviacion_estandar,
              incertidumbre: incertidumbre,
              valor_minimo: valor_minimo,
              valor_maximo: valor_maximo
            }));
          } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setAnalysis(prevState => ({
              ...prevState,
              antecedentes_inverse: [],
              K: [],
              error: error.message,
              coeficientes_ajuste: {
                C1: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
                C2: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
                C3: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null },
                C4: { C_m2: null, C_construcciones: null, C_ubicacion: null, C: null }
              },
              valores_ajustados: { B1: null, B2: null, B3: null, B4: null },
              valor_estimado: null,
              desviacion_estandar: '',
              incertidumbre: '',
              valor_minimo: null,
              valor_maximo: null
            }));
          } finally {
            setIsLoading(false); // Finalizar el estado de carga
            setLoadingStep(''); // Limpiar el mensaje de carga
          }
        }, 200); // Simulación de 1/3 de segundo para el tercer paso
      }, 250); // Simulación de 1/3 de segundo para el segundo paso
    }, 300); // Simulación de 1/3 de segundo para el primer paso
  };

  /**
   * Maneja el envío del formulario adicional para generar el PDF.
   *
   * @param {Object} additionalFormData - Datos del formulario adicional.
   */
  const handleAdditionalSubmit = (additionalFormData) => {
    generatePDF(result, analysis, logoBWBase64, additionalFormData);
  };

  // Depuración: imprimir el análisis cuando cambie
  useEffect(() => {
    if (analysis) {
      console.log('Analysis:', analysis);
    }
  }, [analysis]);

  return (
    <div className={styles.app}>
      <header className={styles.appHeader}>
        <img src={logoPath} className={styles.appLogo} alt="logo" />
      </header>
      <div className={styles.container}>
        <div className={styles.introSection}>
          <div className={styles.introText}>
            <h1>
              <span className={styles.highlightWhite}>Bienvenido</span>
              <span className={styles.highlightBlue}> a Valuation Engine</span>
            </h1>
            <p className={styles.whiteText}>La herramienta de Tasación de Propiedades es un innovador sistema diseñado por Imperium que permite a los agentes inmobiliarios realizar tasaciones rápidas y precisas.</p>
          </div>
          <div className={styles.introImage}>
            <img src="https://imperium.s3.amazonaws.com/pallet_illustrations/undraw_calculator_re_alsc.svg" alt="Descripción de la imagen" />
          </div>
        </div>
        
        {/* Mostrar el overlay si isLoading es verdadero */}
        {isLoading && (
          <div className={styles.overlay}>
            <div className={styles.spinner}></div>
            <p>{loadingStep}</p>
          </div>
        )}

        {/* Renderizar el formulario siempre, pasando los datos actuales */}
        <Form onSubmit={handleSubmit} initialData={formData} />

        {result !== null && (
          <>
            <ProcesoDatos analysis={analysis} />
            <Result 
              valorMinimo={analysis.valor_minimo}
              valorEstimado={analysis.valor_estimado}
              valorMaximo={analysis.valor_maximo}
            />
            {!showAdditionalForm && (
              <button className={styles.generateReportButton} onClick={() => setShowAdditionalForm(true)}>
                Generar Informe
              </button>
            )}
            {showAdditionalForm && (
              <AdditionalForm 
                isOpen={showAdditionalForm} 
                onRequestClose={() => setShowAdditionalForm(false)} 
                onSubmit={handleAdditionalSubmit} 
                initialValues={{
                  ValorMaximo: analysis.valor_maximo,
                  ValorEstimado: analysis.valor_estimado,
                  ValorMinimo: analysis.valor_minimo,
                }}
              />
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default App;
