// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Result.module.css */

/* Contenedor principal para el componente de resultado */
.Result_resultContainer__bpvHJ {
  margin-top: 2rem;
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  border: 1px solid hsla(0, 0%, 100%, 0.105);
  border-radius: 5px;
  background-color: #0f0f0f;
  color: white;
}

/* Estilos para los títulos dentro del componente */
.Result_resultContainer__bpvHJ h2,
.Result_resultContainer__bpvHJ h3 {
  color: white;
  text-align: center;
}

/* Contenedor de la cuadrícula de resultados */
.Result_resultGrid__EpSzP {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 10px 0;
}

/* Cada elemento individual del resultado */
.Result_resultItem__q7n2- {
  background-color: rgba(87, 173, 87, 0.75);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.25);
}

.Result_resultItem__q7n2- h4 {
  margin-bottom: 10px;
  color: #ffffff;
}

.Result_resultItem__q7n2- p {
  background-color: rgba(0, 0, 0, 0.652);
  padding: 5px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Result.module.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC,yDAAyD;AACzD;EACE,gBAAgB;EAChB,aAAa;EACb,cAAc;EACd,gBAAgB;EAChB,0CAA0C;EAC1C,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA,mDAAmD;AACnD;;EAEE,YAAY;EACZ,kBAAkB;AACpB;;AAEA,8CAA8C;AAC9C;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,WAAW;EACX,eAAe;AACjB;;AAEA,2CAA2C;AAC3C;EACE,yCAAyC;EACzC,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,+CAA+C;AACjD;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,sCAAsC;EACtC,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":["/* src/components/Result.module.css */\n\n/* Contenedor principal para el componente de resultado */\n.resultContainer {\n  margin-top: 2rem;\n  padding: 20px;\n  margin: 0 auto;\n  max-width: 800px;\n  border: 1px solid hsla(0, 0%, 100%, 0.105);\n  border-radius: 5px;\n  background-color: #0f0f0f;\n  color: white;\n}\n\n/* Estilos para los títulos dentro del componente */\n.resultContainer h2,\n.resultContainer h3 {\n  color: white;\n  text-align: center;\n}\n\n/* Contenedor de la cuadrícula de resultados */\n.resultGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 20px;\n  width: 100%;\n  padding: 10px 0;\n}\n\n/* Cada elemento individual del resultado */\n.resultItem {\n  background-color: rgba(87, 173, 87, 0.75);\n  border-radius: 5px;\n  padding: 15px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.25);\n}\n\n.resultItem h4 {\n  margin-bottom: 10px;\n  color: #ffffff;\n}\n\n.resultItem p {\n  background-color: rgba(0, 0, 0, 0.652);\n  padding: 5px;\n  border-radius: 3px;\n  width: 100%;\n  text-align: center;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultContainer": `Result_resultContainer__bpvHJ`,
	"resultGrid": `Result_resultGrid__EpSzP`,
	"resultItem": `Result_resultItem__q7n2-`
};
export default ___CSS_LOADER_EXPORT___;
