import React, { useState } from 'react';
import styles from './InfoPopover.module.css';

const InfoPopover = ({ content, position }) => {
  const [isVisible, setIsVisible] = useState(false);

  const togglePopover = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div className={styles.popoverContainer}>
      <i
        className={`fas fa-info-circle ${styles.icon}`}
        onClick={togglePopover}
        onMouseEnter={togglePopover} // Mostrar al pasar el mouse
        onMouseLeave={togglePopover} // Ocultar cuando el mouse sale
      />
      {isVisible && (
        <div className={`${styles.popover} ${styles[position]}`}>
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default InfoPopover;
