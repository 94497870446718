// src/components/ResultPDF.js

import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Función para ajustar la transparencia de una imagen
 * @param {string} imgData - Imagen en formato Base64
 * @param {number} opacity - Opacidad deseada (0 a 1)
 * @returns {Promise<string>} - Promesa que resuelve con la imagen modificada en Base64
 */
const adjustImageOpacity = (imgData, opacity) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgData;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      // Limpiar el canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Establecer la opacidad
      ctx.globalAlpha = opacity;

      // Dibujar la imagen en el canvas con la opacidad
      ctx.drawImage(img, 0, 0);

      // Obtener la imagen modificada en Base64
      const newImgData = canvas.toDataURL('image/png');

      // Resolver la promesa con la nueva imagen
      resolve(newImgData);
    };
    img.onerror = () => {
      reject(new Error('Error al cargar la imagen'));
    };
  });
};

/**
 * Función para generar el informe PDF.
 *
 * @param {Object} result - Resultados de la tasación.
 * @param {Object} analysis - Análisis detallado de la tasación.
 * @param {string} logoBWBase64 - Logo de la empresa en formato Base64.
 * @param {Object} additionalData - Datos adicionales del inmueble provenientes del formulario.
 */
const generatePDF = async (result, analysis, logoBWBase64, additionalData) => {
  try {
    const doc = new jsPDF();

    // Constantes para configuración y estilos
    const COLORS = {
      PRIMARY: [15, 15, 15],              // #0F0F0F (Negro)
      SECONDARY: [54, 66, 94],            // #36425E (Azul oscuro)
      TERTIARY: [197, 175, 120],          // #C5AF78 (Beige/amarillo suave)
      BACKGROUND: [255, 255, 255],        // Blanco
      ALTERNATE: [255, 255, 240],         // #FFFFF0 (Beige muy suave)
      HEADER_BACKGROUND: [230, 230, 230], // #E6E6E6 (Gris muy claro)
    };

    const PAGE_WIDTH = doc.internal.pageSize.getWidth();
    const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
    const TOP_MARGIN = 10;
    const BOTTOM_MARGIN = 20;
    const SIDE_MARGIN = 20;
    const FOOTER_HEIGHT = 20;
    const SECTION_SPACING = 10;       // Espacio entre secciones
    const TITLE_PRE_SPACING = 6;      // Espacio adicional antes de un título
    const TITLE_SPACING = 4;           // Espacio adicional después de un título
    const CONTENT_WIDTH = PAGE_WIDTH - 2 * SIDE_MARGIN;

    // Variables para controlar el estado de la página
    let currentPage = 1;
    let currentHeaderTitle = 'Informe de Tasación';
    let currentYContent = TOP_MARGIN;

    // Cargar y ajustar la opacidad del logo una sola vez
    let transparentLogo = null;
    if (logoBWBase64) {
      try {
        transparentLogo = await adjustImageOpacity(logoBWBase64, 0.5);
      } catch (error) {
        console.error(error.message);
        // Manejo alternativo si la imagen no se puede cargar
        transparentLogo = null;
      }
    }

    // Funciones auxiliares

    /**
     * Añadir encabezado y pie de página
     * @param {string} title - Título del encabezado
     * @param {number} pageNumber - Número de página
     */
    const addHeaderAndFooter = async (title, pageNumber) => {
      // Encabezado con fondo muy claro
      const HEADER_HEIGHT = TOP_MARGIN + 10;
      doc.setFillColor(...COLORS.HEADER_BACKGROUND);
      doc.rect(0, 0, PAGE_WIDTH, HEADER_HEIGHT, 'F');

      // Logo en el encabezado si existe, alineado a la derecha
      if (transparentLogo) {
        const ACTUAL_LOGO_WIDTH = 512;
        const ACTUAL_LOGO_HEIGHT = 237;
        const ASPECT_RATIO = ACTUAL_LOGO_WIDTH / ACTUAL_LOGO_HEIGHT;

        const DESIRED_LOGO_WIDTH = 30;
        const DESIRED_LOGO_HEIGHT = DESIRED_LOGO_WIDTH / ASPECT_RATIO;

        const logoX = PAGE_WIDTH - SIDE_MARGIN - DESIRED_LOGO_WIDTH;
        const logoY = (HEADER_HEIGHT - DESIRED_LOGO_HEIGHT) / 2;

        doc.addImage(transparentLogo, 'PNG', logoX, logoY, DESIRED_LOGO_WIDTH, DESIRED_LOGO_HEIGHT);
      }

      // Título en el encabezado
      if (title) {
        const TITLE_FONT_SIZE = 14;
        doc.setFontSize(TITLE_FONT_SIZE);
        doc.setTextColor(0, 0, 0);
        doc.setFont('Helvetica', 'bold');

        const titleHeight = TITLE_FONT_SIZE * 0.35;
        const titleY = (HEADER_HEIGHT + titleHeight) / 2;

        doc.text(title, PAGE_WIDTH / 2, titleY, { align: 'center' });
      }

      // Pie de página
      doc.setFontSize(10);
      doc.setTextColor(...COLORS.SECONDARY);
      doc.setFont('Helvetica', 'normal');

      // Línea divisoria en el pie de página
      doc.setDrawColor(...COLORS.TERTIARY);
      doc.setLineWidth(0.5);
      doc.line(
        SIDE_MARGIN,
        PAGE_HEIGHT - FOOTER_HEIGHT + 5,
        PAGE_WIDTH - SIDE_MARGIN,
        PAGE_HEIGHT - FOOTER_HEIGHT + 5
      );

      // Número de página en la parte inferior derecha
      doc.text(`Página ${pageNumber}`, PAGE_WIDTH - SIDE_MARGIN, PAGE_HEIGHT - 10, {
        align: 'right',
        baseline: 'bottom',
      });

      // URL de la empresa en la parte inferior central
      doc.text('www.inmobiliariaimperium.com', PAGE_WIDTH / 2, PAGE_HEIGHT - 10, {
        align: 'center',
        baseline: 'bottom',
      });

      // Actualizar currentYContent después del encabezado con un mayor margen superior
      currentYContent = TOP_MARGIN + 30; // Aumento del margen superior después del encabezado
    };

    /**
     * Verificar si es necesario agregar una nueva página
     * @param {number} additionalHeight - Altura adicional requerida
     */
    const checkAddPage = async (additionalHeight = 0) => {
      if (
        currentYContent + additionalHeight >
        PAGE_HEIGHT - BOTTOM_MARGIN - FOOTER_HEIGHT
      ) {
        doc.addPage();
        currentPage++;
        await addHeaderAndFooter(currentHeaderTitle, currentPage);
        currentYContent = TOP_MARGIN + 30; // Aumento del margen superior después del encabezado en nuevas páginas
      }
    };

    /**
     * Añadir texto largo y ajustarlo dinámicamente en la página
     * @param {string} text - Texto a añadir
     * @param {Object} options - Opciones de formato
     */
    const addLongText = async (text, options = {}) => {
      const {
        fontSize = 12,
        textColor = [0, 0, 0],
        fontStyle = 'normal',
        align = 'left',
        title = '',
      } = options;

      if (title) {
        // Añadir espacio antes del título
        currentYContent += TITLE_PRE_SPACING;

        // Añadir título si se proporciona
        doc.setFontSize(fontSize + 2);
        doc.setTextColor(...COLORS.PRIMARY);
        doc.setFont('Helvetica', 'bold');
        await checkAddPage(10);
        doc.text(title, SIDE_MARGIN, currentYContent);
        currentYContent += 7;

        // Añadir espacio adicional después del título
        currentYContent += TITLE_SPACING;
      }

      doc.setFontSize(fontSize);
      doc.setTextColor(...textColor);
      doc.setFont('Helvetica', fontStyle);

      // Dividir el texto para ajustarlo al ancho de la página
      const textLines = doc.splitTextToSize(text, CONTENT_WIDTH);
      const lineHeight = 7;

      for (const line of textLines) {
        // Verificar si hay suficiente espacio para la próxima línea de texto
        if (
          currentYContent + lineHeight >
          PAGE_HEIGHT - BOTTOM_MARGIN - FOOTER_HEIGHT
        ) {
          // Agregar nueva página si el contenido excede el espacio disponible
          doc.addPage();
          currentPage++;
          await addHeaderAndFooter(currentHeaderTitle, currentPage);

          // Restablecer el color de texto y otros estilos
          doc.setFontSize(fontSize);
          doc.setTextColor(...textColor);
          doc.setFont('Helvetica', fontStyle);

          currentYContent = TOP_MARGIN + 30; // Aumento del margen superior después del encabezado en nuevas páginas
        }

        // Añadir la línea de texto
        doc.text(line, SIDE_MARGIN, currentYContent, { align });
        currentYContent += lineHeight;
      }
    };

    /**
     * Añadir texto formateado con diferentes estilos
     * @param {Array} fragments - Array de fragmentos de texto con estilos
     * @param {Object} options - Opciones de formato
     */
    const addFormattedText = async (fragments, options = {}) => {
      const {
        fontSize = 12,
        textColor = [0, 0, 0],
        align = 'left',
        maxWidth = CONTENT_WIDTH,
      } = options;

      doc.setFontSize(fontSize);
      doc.setTextColor(...textColor);

      let x = SIDE_MARGIN;
      let y = currentYContent;
      const lineHeight = 7;
      let wordsInLine = [];
      let lineWidth = 0;

      for (const fragment of fragments) {
        const words = fragment.text.split(' ');
        for (const word of words) {
          const wordWithSpace = word + ' ';
          const wordWidth = doc.getTextWidth(wordWithSpace);
          if (lineWidth + wordWidth > maxWidth) {
            // Renderizar la línea actual
            for (const w of wordsInLine) {
              doc.setFont('Helvetica', w.style);
              doc.text(w.text + ' ', x, y);
              x += doc.getTextWidth(w.text + ' ');
            }
            // Mover a la siguiente línea
            y += lineHeight;
            x = SIDE_MARGIN;
            // Verificar si hay espacio en la página
            if (y + lineHeight > PAGE_HEIGHT - BOTTOM_MARGIN - FOOTER_HEIGHT) {
              await checkAddPage();
              y = currentYContent;
            }
            // Reiniciar línea
            wordsInLine = [];
            lineWidth = 0;
          }
          // Añadir la palabra a la línea actual
          wordsInLine.push({ text: word, style: fragment.style });
          lineWidth += wordWidth;
        }
      }
      // Renderizar cualquier texto restante en la línea
      if (wordsInLine.length > 0) {
        for (const w of wordsInLine) {
          doc.setFont('Helvetica', w.style);
          doc.text(w.text + ' ', x, y);
          x += doc.getTextWidth(w.text + ' ');
        }
      }
      currentYContent = y + lineHeight;
    };

    /**
     * Función auxiliar para formatear los valores de moneda
     * @param {number} value - Valor numérico a formatear
     * @returns {string} - Valor formateado como cadena con tres decimales
     */
    const formatCurrency = (value) => {
      if (isNaN(value)) return 'N/A';
      return `U$S ${value.toLocaleString('es-ES', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`;
    };

    /**
     * Añadir una tabla al PDF usando autoTable
     * @param {Array} head - Encabezados de la tabla
     * @param {Array} body - Cuerpo de la tabla
     * @param {Object} columnStyles - Estilos de las columnas
     */
    const addTable = async (head, body, columnStyles = {}) => {
      // Estimar altura requerida para la tabla
      const tableHeight = body.length * 10 + 30; // Aproximación
      await checkAddPage(tableHeight + 20); // +20 para el título y espacio adicional

      doc.autoTable({
        startY: currentYContent,
        head: head,
        body: body,
        theme: 'grid',
        styles: { fontSize: 12, cellPadding: 3, font: 'Helvetica' },
        headStyles: {
          fillColor: COLORS.BACKGROUND,
          textColor: 0,
          fontStyle: 'bold',
        },
        alternateRowStyles: {
          fillColor: COLORS.ALTERNATE,
        },
        columnStyles: columnStyles,
        margin: { left: SIDE_MARGIN, right: SIDE_MARGIN },
        pageBreak: 'avoid',
        tableLineColor: COLORS.SECONDARY,
        tableLineWidth: 0.5,
      });

      currentYContent = doc.lastAutoTable.finalY + SECTION_SPACING;
    };

    // ============================
    // Portada (Primera Página)
    // ============================

    // Añadir logo en la parte superior de la portada
    if (logoBWBase64) {
      const ACTUAL_LOGO_WIDTH = 512;
      const ACTUAL_LOGO_HEIGHT = 237;
      const ASPECT_RATIO = ACTUAL_LOGO_WIDTH / ACTUAL_LOGO_HEIGHT;

      const LOGO_WIDTH = 100;
      const LOGO_HEIGHT = LOGO_WIDTH / ASPECT_RATIO;

      const logoX = (PAGE_WIDTH - LOGO_WIDTH) / 2;
      const logoY = currentYContent;
      doc.addImage(logoBWBase64, 'PNG', logoX, logoY, LOGO_WIDTH, LOGO_HEIGHT);
      currentYContent += LOGO_HEIGHT + 12;
    }

    // Datos de la empresa debajo del logo
    const companyData = [
      'IMPERIUM GLOBAL S.R.L',
      'R.U.T: 21 8234 12 0016',
      'Joaquín Núñez 2890, Montevideo',
      'Número de Operador Turístico: 1772',
    ];

    doc.setFontSize(10);
    doc.setTextColor(...COLORS.SECONDARY);
    doc.setFont('Helvetica', 'normal');
    companyData.forEach((text) => {
      doc.text(text, PAGE_WIDTH / 2, currentYContent, { align: 'center' });
      currentYContent += 6;
    });

    currentYContent += 5;

    // Línea divisoria después de los datos de la empresa
    doc.setDrawColor(...COLORS.SECONDARY);
    doc.setLineWidth(0.5);
    doc.line(SIDE_MARGIN, currentYContent, PAGE_WIDTH - SIDE_MARGIN, currentYContent);
    currentYContent += 5;

    // Ajustar la posición del título principal
    currentYContent += 10;

    // Título principal
    doc.setFontSize(22);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Recomendación de Valor Fundamentada', PAGE_WIDTH / 2, currentYContent, {
      align: 'center',
    });
    currentYContent += 20;

    // Agente inmobiliario
    doc.setFontSize(16);
    doc.setTextColor(...COLORS.TERTIARY);
    doc.setFont('Helvetica', 'bold');
    doc.text(
      `Agente Inmobiliario: ${additionalData?.AgenteInmobiliario || 'No especificado'}`,
      PAGE_WIDTH / 2,
      currentYContent,
      { align: 'center' }
    );
    currentYContent += 15;

    // Fecha del informe
    doc.setFontSize(14);
    doc.setTextColor(...COLORS.SECONDARY);
    doc.setFont('Helvetica', 'normal');
    const reportDate = additionalData?.Fecha
      ? new Date(additionalData.Fecha).toLocaleDateString('es-ES')
      : new Date().toLocaleDateString('es-ES');

    doc.text(`Fecha del Informe: ${reportDate}`, PAGE_WIDTH / 2, currentYContent, {
      align: 'center',
    });
    currentYContent += 20;

    // Datos del inmueble en la portada
    const propertyData = [
      { label: 'Tipo de Propiedad:', value: additionalData?.TipoDePropiedad },
      { label: 'Dirección:', value: additionalData?.Direccion },
      { label: 'Departamento:', value: additionalData?.Departamento },
      { label: 'Padrón:', value: additionalData?.Padron },
    ];

    doc.setFontSize(12);
    doc.setTextColor(...COLORS.SECONDARY);
    doc.setFont('Helvetica', 'normal');
    propertyData.forEach((item) => {
      if (item.value) {
        doc.text(
          `${item.label} ${item.value}`,
          PAGE_WIDTH / 2,
          currentYContent,
          { align: 'center' }
        );
        currentYContent += 8;
      }
    });

    // Línea antes del Propósito
    doc.setDrawColor(...COLORS.SECONDARY);
    doc.setLineWidth(0.5);
    const yInicio = currentYContent + 10;
    doc.line(SIDE_MARGIN, yInicio, PAGE_WIDTH - SIDE_MARGIN, yInicio);
    currentYContent += 15;

    // Calcular la posición vertical central entre las dos líneas
    const yFin = currentYContent + 35;
    const yCentro = (yInicio + yFin) / 2;

    // Propósito de IMPERIUM
    const propositoText =
      'En IMPERIUM, nuestro propósito es brindar un servicio de calidad 5 estrellas.';
    doc.setFontSize(12);
    doc.setTextColor(200, 200, 200); // Color gris para el propósito
    doc.setFont('Helvetica', 'normal');
    doc.text(propositoText, PAGE_WIDTH / 2, yCentro, { align: 'center' });

    // Línea al final de la portada
    doc.setDrawColor(...COLORS.SECONDARY);
    doc.setLineWidth(0.5);
    doc.line(SIDE_MARGIN, yFin, PAGE_WIDTH - SIDE_MARGIN, yFin);
    currentYContent = yFin + 20;

    // Nueva página para continuar con el contenido
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Datos del Inmueble'; // Cambio realizado aquí
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // ============================
    // Segunda Página - Datos del Inmueble
    // ============================

    // Definir los campos adicionales del inmueble y filtrar aquellos que tienen valores
    const additionalPropertyFields = [
      {
        label: 'Superficie Construida (m²):',
        value: additionalData?.SuperficieConstruida,
      },
      { label: 'Dormitorios:', value: additionalData?.Dormitorios },
      { label: 'Baños:', value: additionalData?.Banos },
      { label: 'Garaje:', value: additionalData?.Garaje },
      { label: 'Número de Piso:', value: additionalData?.NumeroDePiso },
      { label: 'Disposición:', value: additionalData?.Disposicion },
      { label: 'Gastos Comunes:', value: additionalData?.GastosComunes },
      { label: 'Cantidad de Plantas:', value: additionalData?.CantidadDePlantas },
      {
        label: 'Estado de Conservación:',
        value: additionalData?.EstadoDeConservacion,
      },
      { label: 'Luminosidad:', value: additionalData?.Luminosidad },
    ];

    // Filtrar solo los campos que tienen un valor definido y no están vacíos
    const additionalPropertyData = additionalPropertyFields
      .filter(
        (field) =>
          field.value !== undefined && field.value !== null && field.value !== ''
      )
      .map((field) => [field.label, field.value]);

    if (additionalPropertyData.length > 0) {
      // ============================
      // Añadir Título para la Tabla de Datos del Inmueble
      // ============================

      await addLongText('Datos del Inmueble', {
        fontSize: 14,                // Tamaño de fuente adecuado para un título
        textColor: COLORS.PRIMARY,  // Color principal definido en las constantes
        fontStyle: 'bold',           // Estilo de fuente en negrita para resaltar el título
        align: 'left',               // Alineación a la izquierda
      });

      // Añadir espacio adicional después del título
      currentYContent += TITLE_SPACING;

      // ============================
      // Añadir Tabla de Datos del Inmueble
      // ============================

      await addTable(
        [['Campo', 'Descripción']],
        additionalPropertyData,
        {
          0: {
            fontStyle: 'bold',
            textColor: COLORS.PRIMARY,
            cellWidth: 'auto',
            // Alineamiento predeterminado (izquierda)
          },
          1: {
            textColor: 0,
            cellWidth: 'auto',
            // Alineamiento predeterminado (izquierda)
          },
        }
      );
    }

    // Añadir 'Descripción' como texto separado con título
    if (additionalData?.Descripcion) {
      await addLongText(additionalData.Descripcion, {
        fontSize: 12,
        textColor: [0, 0, 0],
        fontStyle: 'normal',
        title: 'Descripción',
      });
    }

    // Añadir 'Observaciones' como texto separado con título (Formato igual que "Descripción")
    if (additionalData?.Observaciones) {
      await addLongText(additionalData.Observaciones, {
        fontSize: 12,
        textColor: [0, 0, 0],
        fontStyle: 'normal',
        title: 'Observaciones', // Aseguramos que "Observaciones" tenga el mismo formato que "Descripción"
      });
    }

    // ============================
    // Tercera Página - Metodología de Tasación
    // ============================

    // Añadir nueva página para la metodología
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Metodología de Tasación';
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // Añadir título de la sección
    doc.setFontSize(16);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Metodología de Tasación', SIDE_MARGIN, currentYContent);
    currentYContent += 10;

    // Texto de la metodología
    const metodologiaText = `
    La valoración del inmueble se realiza mediante el Método Científico Metrológico Normalizado y Formalizado IRAM (UNIT) – ISO 34854:2003. Este método, basado en la comparación directa de ofertas, permite analizar objetivamente los valores actuales del mercado para propiedades similares, ubicadas en zonas comparables.

    El proceso incluye:
    - Evaluación de las cualidades intrínsecas (propias del bien) y extrínsecas (entorno y localización).
    - Uso de coeficientes de ajuste que homogeneizan datos para eliminar diferencias significativas entre las propiedades comparadas.
    - Cálculo mediante una planilla de regresión poligonal que permite estimar el valor más probable del inmueble.

    Finalmente, se determina un rango de valores (mínimo y máximo) junto con un nivel de incertidumbre y confianza, proporcionando así una estimación precisa y fundamentada del valor de mercado.
    `;

    // Añadir el texto de metodología
    await addLongText(metodologiaText.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // Añadir Explicación Simple para Metodología de Tasación
    const explicacionMetodologia = `
    Para calcular el valor de tu propiedad, hemos comparado inmuebles similares en la misma zona, teniendo en cuenta características como el tamaño, calidad de construcción y ubicación. Ajustamos las diferencias entre estos inmuebles y el tuyo para asegurar que la valoración sea precisa y refleje el valor real del mercado actual.
    `;
    await addLongText(explicacionMetodologia.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // ============================
    // Cuarta Página - Antecedentes de Inmuebles Similares
    // ============================

    // Añadir nueva página para Antecedentes
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Antecedentes de Inmuebles Similares';
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // Título de la sección
    doc.setFontSize(14);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Antecedentes de Inmuebles Similares', SIDE_MARGIN, currentYContent);
    currentYContent += 10;

    // Tabla de Antecedentes de Inmuebles Similares a la Venta
    const antecedentesHead = [['ID', 'X', 'M²', 'Construcción', 'Ubicación', 'Valor de Venta']];
    const antecedentesBody = analysis.antecedentes.map((row, index) => [
      `A${index + 1}`,
      1, // X siempre es 1
      row.m2,
      row.construccion,
      row.ubicacion,
      `U$S ${Math.round(analysis.valores_venta[index]).toLocaleString('es-ES', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`,
    ]);

    // Añadir tabla de Antecedentes con datos y encabezados centrados
    await addTable(antecedentesHead, antecedentesBody, {
      0: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      1: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      2: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      3: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      4: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      5: { textColor: 0, cellWidth: 'auto', halign: 'center' },
    });

    // Añadir Explicación Simple para Antecedentes
    const explicacionAntecedentes = `
    Hemos seleccionado estas propiedades porque son similares en características y ubicación a la tuya. Al analizar cómo se valoran estas propiedades en el mercado, podemos estimar un valor justo y actualizado para tu inmueble.
    `;
    await addLongText(explicacionAntecedentes.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // Título de la sección Bien Problema
    doc.setFontSize(14);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Bien Problema', SIDE_MARGIN, currentYContent);
    currentYContent += 10;

    // Tabla de Bien Problema con datos y encabezados centrados
    const bienProblemaHead = [['ID', 'X', 'M²', 'Construcción', 'Ubicación']];
    const bienProblemaBody = [
      [
        'BP',
        1,
        analysis.bien_problema.m2,
        analysis.bien_problema.construccion,
        analysis.bien_problema.ubicacion,
      ],
    ];

    await addTable(bienProblemaHead, bienProblemaBody, {
      0: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      1: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      2: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      3: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      4: { textColor: 0, cellWidth: 'auto', halign: 'center' },
    });

    // Añadir Explicación Simple para Bien Problema
    const explicacionBienProblema = `
    Aquí se muestran las características principales de tu propiedad, que utilizamos para compararla con las otras propiedades seleccionadas en el mercado. Esto nos ayuda a entender cómo varía su valor en función de sus atributos específicos.
    `;
    await addLongText(explicacionBienProblema.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // ============================
    // Quinta Página - Proceso de Datos
    // ============================

    // Añadir nueva página para Proceso de Datos
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Proceso de Datos';
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // Añadir título para Matriz Inversa
    await addLongText('Matriz Inversa de Antecedentes', {
      fontSize: 14,
      textColor: COLORS.PRIMARY,
      fontStyle: 'bold',
      align: 'left',
    });

    // Matriz Inversa de Antecedentes
    const matrizInversaBody =
      analysis.antecedentes_inverse.length > 0
        ? analysis.antecedentes_inverse.map((row) => row.map((value) => parseFloat(value).toFixed(4)))
        : [['No hay datos disponibles para la matriz inversa.']];

    // Añadir tabla de Matriz Inversa con alineamiento predeterminado
    await checkAddPage(matrizInversaBody.length * 10 + 30);
    doc.autoTable({
      startY: currentYContent,
      head: [],
      body: matrizInversaBody,
      theme: 'grid',
      styles: { fontSize: 10, halign: 'center', cellPadding: 3, font: 'Helvetica' },
      alternateRowStyles: {
        fillColor: COLORS.ALTERNATE,
      },
      margin: { left: SIDE_MARGIN, right: SIDE_MARGIN },
      pageBreak: 'avoid',
      tableLineColor: COLORS.SECONDARY,
      tableLineWidth: 0.5,
    });
    currentYContent = doc.lastAutoTable.finalY + SECTION_SPACING;

    // Añadir título para Matriz K
    await addLongText('Matriz K', {
      fontSize: 14,
      textColor: COLORS.PRIMARY,
      fontStyle: 'bold',
      align: 'left',
    });

    // Matriz K
    if (analysis.K && analysis.K.length > 0) {
      const matrizKBody = analysis.K.map((row) => row.map((value) => parseFloat(value).toFixed(4)));

      // Añadir tabla de Matriz K con datos y encabezados centrados
      await checkAddPage(matrizKBody.length * 10 + 30);
      doc.autoTable({
        startY: currentYContent,
        head: [],
        body: matrizKBody,
        theme: 'grid',
        styles: { fontSize: 10, halign: 'center', cellPadding: 3, font: 'Helvetica' },
        alternateRowStyles: {
          fillColor: COLORS.ALTERNATE,
        },
        margin: { left: SIDE_MARGIN, right: SIDE_MARGIN },
        pageBreak: 'avoid',
        tableLineColor: COLORS.SECONDARY,
        tableLineWidth: 0.5,
      });
      currentYContent = doc.lastAutoTable.finalY + SECTION_SPACING;
    } else {
      // Si no hay Matriz K disponible
      await addLongText('Matriz K no disponible', {
        fontSize: 12,
        textColor: [0, 0, 0],
        fontStyle: 'bold',
        align: 'center',
      });
    }

    // Añadir Explicación Simple para Matrices
    const explicacionMatrices = `
    Estas matrices se calculan para analizar y ajustar las características de las propiedades comparables en relación con tu inmueble. La Matriz Inversa y la Matriz K nos permiten determinar cómo cada característica (como el tamaño o la ubicación) influye en el valor de las propiedades. Esta información es fundamental para calcular los coeficientes de ajuste que se usan en la siguiente etapa para homogeneizar las diferencias entre las propiedades comparables y tu propiedad.
    `;
    await addLongText(explicacionMatrices.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // ============================
    // Sexta Página - Coeficientes de Ajuste
    // ============================

    // Añadir nueva página para Coeficientes de Ajuste
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Coeficientes de Ajuste';
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // Título de la sección
    doc.setFontSize(12);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Coeficientes de Ajuste', SIDE_MARGIN, currentYContent);
    currentYContent += 10;

    // Datos de Coeficientes de Ajuste
    const coeficientesAjusteHead = [
      ['Coeficiente', 'C_m²', 'C_construcciones', 'C_ubicacion', 'C'],
    ];
    const coeficientesAjusteBody = Object.keys(analysis.coeficientes_ajuste).map((key) => {
      const coef = analysis.coeficientes_ajuste[key];
      return [
        key,
        coef.C_m2 !== null ? coef.C_m2.toFixed(4) : 'N/A',
        coef.C_construcciones !== null ? coef.C_construcciones.toFixed(4) : 'N/A',
        coef.C_ubicacion !== null ? coef.C_ubicacion.toFixed(4) : 'N/A',
        coef.C !== null ? coef.C.toFixed(4) : 'N/A',
      ];
    });

    // Añadir tabla de Coeficientes de Ajuste con datos y encabezados centrados
    await addTable(coeficientesAjusteHead, coeficientesAjusteBody, {
      0: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      1: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      2: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      3: { textColor: 0, cellWidth: 'auto', halign: 'center' },
      4: { textColor: 0, cellWidth: 'auto', halign: 'center' },
    });

    // Añadir Explicación Simple para Coeficientes de Ajuste
    const explicacionCoeficientes = `
    Utilizando las matrices calculadas anteriormente, determinamos los coeficientes de ajuste. Estos coeficientes nos permiten ajustar los valores de las propiedades comparables para que reflejen de manera precisa las diferencias con tu inmueble, asegurando que la valoración sea exacta y esté basada en datos reales del mercado. Es un paso crucial que nos ayuda a homogeneizar las características y establecer un valor preciso y ajustado para tu propiedad.
    `;
    await addLongText(explicacionCoeficientes.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // ============================
    // Séptima Página - Valores Ajustados
    // ============================

    // Añadir nueva página para Valores Ajustados
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Valores Ajustados';
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // Título de la sección
    doc.setFontSize(12);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Valores Ajustados', SIDE_MARGIN, currentYContent);
    currentYContent += 10;

    // Datos de Valores Ajustados
    const valoresAjustados = [
      ['Descripción', 'Valor'],
      [
        'V1',
        analysis.valores_ajustados.B1 !== null
          ? `U$S ${parseFloat(analysis.valores_ajustados.B1).toLocaleString('es-ES', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}`
          : 'N/A',
      ],
      [
        'V2',
        analysis.valores_ajustados.B2 !== null
          ? `U$S ${parseFloat(analysis.valores_ajustados.B2).toLocaleString('es-ES', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}`
          : 'N/A',
      ],
      [
        'V3',
        analysis.valores_ajustados.B3 !== null
          ? `U$S ${parseFloat(analysis.valores_ajustados.B3).toLocaleString('es-ES', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}`
          : 'N/A',
      ],
      [
        'V4',
        analysis.valores_ajustados.B4 !== null
          ? `U$S ${parseFloat(analysis.valores_ajustados.B4).toLocaleString('es-ES', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}`
          : 'N/A',
      ],
    ];

    // Añadir tabla de Valores Ajustados con alineamiento específico
    await addTable(
      [valoresAjustados[0]],
      valoresAjustados.slice(1),
      {
        0: {
          fontStyle: 'bold',
          textColor: 0,
          halign: 'right', // Alinear descripción a la derecha
          fillColor: [230, 230, 230],
          cellWidth: 'auto',
        },
        1: {
          halign: 'left', // Alinear valor a la izquierda
          cellWidth: 'auto',
          textColor: 0,
        },
      }
    );

    // Añadir Explicación Simple para Valores Ajustados
    const explicacionValoresAjustados = `
    Hemos ajustado los valores de las propiedades comparables usando los coeficientes calculados para que reflejen mejor las características de tu inmueble. Estos valores ajustados nos ayudan a determinar un valor probable de mercado para tu propiedad.
    `;
    await addLongText(explicacionValoresAjustados.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
    });

    // ============================
    // Octava Página - Resultados de la Tasación
    // ============================

    // Añadir nueva página para Resultados de la Tasación
    doc.addPage();
    currentPage++;
    currentHeaderTitle = 'Resultados de la Tasación';
    await addHeaderAndFooter(currentHeaderTitle, currentPage);

    // Título de la sección
    doc.setFontSize(12);
    doc.setTextColor(...COLORS.PRIMARY);
    doc.setFont('Helvetica', 'bold');
    doc.text('Resultados de la Tasación', SIDE_MARGIN, currentYContent);
    currentYContent += 10;

    // Acceder a los valores desde additionalData y asegurarnos de que sean numéricos
    const valorEstimado = parseFloat(additionalData.ValorEstimado) || 0;
    const valorMinimo = parseFloat(additionalData.ValorMinimo) || 0;
    const valorMaximo = parseFloat(additionalData.ValorMaximo) || 0;

    const resultDataArray = [
      ['Valor Estimado:', formatCurrency(valorEstimado)],
      ['Valor Mínimo:', formatCurrency(valorMinimo)],
      ['Valor Máximo:', formatCurrency(valorMaximo)],
      ['Incertidumbre:', `${result.incertidumbre || 'N/A'}`],
      ['Nivel de Confianza:', `90%`],
    ];

    // Añadir tabla de Resultados de la Tasación con alineamiento específico
    await addTable(
      [],
      resultDataArray,
      {
        0: {
          fontStyle: 'bold',
          textColor: 0,
          halign: 'right', // Alinear descripción a la derecha
          fillColor: [230, 230, 230],
          cellWidth: 'auto',
        },
        1: {
          halign: 'left', // Alinear valor a la izquierda
          cellWidth: 'auto',
          textColor: 0,
        },
      }
    );

    // Crear los fragmentos de texto para el Resumen Final con negrita en los datos dinámicos
    const resumenFinalFragments = [
      { text: 'El valor de tasación del inmueble, en la fecha indicada ', style: 'normal' },
      { text: reportDate, style: 'bold' },
      { text: ', se encuentra en un rango de ', style: 'normal' },
      { text: formatCurrency(valorMinimo), style: 'bold' },
      { text: ' a ', style: 'normal' },
      { text: formatCurrency(valorMaximo), style: 'bold' },
      { text: ', siendo ', style: 'normal' },
      { text: formatCurrency(valorEstimado), style: 'bold' },
      { text: ' el precio más probable de transacción en el mercado actual.', style: 'normal' },
    ];

    // Añadir Resumen Final con texto formateado
    await addLongText('', {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
      title: 'Resumen Final',
    });

    // Añadir el texto formateado
    await addFormattedText(resumenFinalFragments, {
      fontSize: 12,
      textColor: [0, 0, 0],
      align: 'left',
    });

    // Nota con título
    const notaText = `
    El rango de valores mínimo y máximo representa la referencia recomendada para la operación inmobiliaria. El valor máximo estimado se sugiere como punto de partida para la propuesta de precio de venta, mientras que el valor mínimo se considera como el umbral razonable para aceptar y concretar la transacción.
    El precio más probable que hemos calculado para tu propiedad se encuentra dentro de un rango que toma en cuenta las fluctuaciones del mercado y las características específicas de tu inmueble. Esto te proporciona una referencia clara y fundamentada para establecer un precio de venta o tomar decisiones.
    `;
    await addLongText(notaText.trim(), {
      fontSize: 12,
      textColor: [0, 0, 0],
      fontStyle: 'normal',
      title: 'Nota',
    });

    // ============================
    // Guardar el PDF
    // ============================
    doc.save('informe_tasacion.pdf');
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};

export default generatePDF;
