// src/components/AdditionalForm.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CurrencyInput from 'react-currency-input-field';
import styles from './AdditionalForm.module.css';

// Configuración de react-modal para accesibilidad
Modal.setAppElement('#root');

const AdditionalForm = ({ isOpen, onRequestClose, onSubmit, initialValues }) => {
  const [additionalData, setAdditionalData] = useState({
    AgenteInmobiliario: '',
    Direccion: '',
    TipoDePropiedad: '',
    Barrio: '',
    Departamento: '',
    Padron: '',
    Fecha: '',
    SuperficieConstruida: '',
    Dormitorios: '',
    Banos: '',
    Garaje: '',
    NumeroDePiso: '',
    Disposicion: '',
    GastosComunes: '',
    CantidadDePlantas: '',
    EstadoDeConservacion: '',
    Luminosidad: '',
    Descripcion: '',
    Observaciones: '',
    ValorMaximo: '',    // Campo de moneda como cadena
    ValorEstimado: '',  // Campo de moneda como cadena
    ValorMinimo: '',    // Campo de moneda como cadena
  });

  const [modifiedFields, setModifiedFields] = useState({
    ValorMaximo: false,
    ValorEstimado: false,
    ValorMinimo: false,
  });

  const [error, setError] = useState('');

  // Prellenar los campos con valores iniciales cuando recibimos `initialValues`
  useEffect(() => {
    if (initialValues) {
      console.log('Recibiendo initialValues en AdditionalForm:', initialValues);
      setAdditionalData(prevData => ({
        ...prevData,
        ValorMaximo: (typeof initialValues.ValorMaximo === 'number') ? initialValues.ValorMaximo.toFixed(3) : '',
        ValorEstimado: (typeof initialValues.ValorEstimado === 'number') ? initialValues.ValorEstimado.toFixed(3) : '',
        ValorMinimo: (typeof initialValues.ValorMinimo === 'number') ? initialValues.ValorMinimo.toFixed(3) : '',
      }));
    }
  }, [initialValues]);

  // Depurar cambios en additionalData
  useEffect(() => {
    console.log('additionalData actualizado:', additionalData);
  }, [additionalData]);

  // Función para determinar las clases de CurrencyInput
  const getCurrencyInputClass = (fieldName) => {
    return `${styles.currencyInput} ${
      !modifiedFields[fieldName] && additionalData[fieldName] ? styles.prefilledText : ''
    }`;
  };

  // Manejar cambios en los campos
  const handleChange = (name, value) => {
    console.log(`Field Changed - ${name}: ${value}`);
    setAdditionalData(prevData => ({
      ...prevData,
      [name]: value || '',
    }));

    // Si el usuario modifica el campo, marcamos que ha sido modificado
    if (!modifiedFields[name]) {
      setModifiedFields(prevFields => ({
        ...prevFields,
        [name]: true,
      }));
    }

    console.log('Modified Fields:', modifiedFields);
  };

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Validación de campos obligatorios
    if (
      !additionalData.AgenteInmobiliario ||
      !additionalData.Direccion ||
      !additionalData.TipoDePropiedad ||
      !additionalData.Departamento ||
      !additionalData.Padron
    ) {
      setError('Por favor, complete los campos obligatorios.');
      return;
    }

    // Función para convertir cadena a número
    const parseNumber = (value) => {
      if (!value) return NaN;
      // Remover separadores de miles ','
      const normalized = value.replace(/,/g, '');
      return parseFloat(normalized);
    };

    const valorMaximoNum = parseNumber(additionalData.ValorMaximo);
    const valorEstimadoNum = parseNumber(additionalData.ValorEstimado);
    const valorMinimoNum = parseNumber(additionalData.ValorMinimo);

    // Validación de los campos de valor
    if (
      isNaN(valorMaximoNum) ||
      isNaN(valorEstimadoNum) ||
      isNaN(valorMinimoNum)
    ) {
      setError('Por favor, ingrese valores numéricos válidos para los campos de valor.');
      return;
    }

    // Validar que Valor Mínimo ≤ Valor Estimado ≤ Valor Máximo
    if (valorMinimoNum > valorEstimadoNum || valorEstimadoNum > valorMaximoNum) {
      setError('Asegúrese de que Valor Mínimo ≤ Valor Estimado ≤ Valor Máximo.');
      return;
    }

    try {
      // Pasar los valores numéricos al manejar el submit
      onSubmit({
        ...additionalData,
        ValorMaximo: valorMaximoNum,
        ValorEstimado: valorEstimadoNum,
        ValorMinimo: valorMinimoNum,
      });
      onRequestClose();
    } catch (err) {
      setError('Ocurrió un error al procesar el formulario.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Formulario Adicional"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2>Datos del Inmueble</h2>
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.formGrid}>
          {/* Campo: Agente Inmobiliario */}
          <div className={styles.formGroup}>
            <label htmlFor="AgenteInmobiliario">
              Agente Inmobiliario: <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              id="AgenteInmobiliario"
              name="AgenteInmobiliario"
              value={additionalData.AgenteInmobiliario}
              onChange={(e) => handleChange('AgenteInmobiliario', e.target.value)}
              required
            />
          </div>

          {/* Campo: Dirección */}
          <div className={styles.formGroup}>
            <label htmlFor="Direccion">
              Dirección: <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              id="Direccion"
              name="Direccion"
              value={additionalData.Direccion}
              onChange={(e) => handleChange('Direccion', e.target.value)}
              required
            />
          </div>

          {/* Campo: Tipo de Propiedad */}
          <div className={styles.formGroup}>
            <label htmlFor="TipoDePropiedad">
              Tipo de Propiedad: <span className={styles.required}>*</span>
            </label>
            <select
              id="TipoDePropiedad"
              name="TipoDePropiedad"
              value={additionalData.TipoDePropiedad}
              onChange={(e) => handleChange('TipoDePropiedad', e.target.value)}
              required
            >
              <option value="">Seleccione</option>
              <option value="Casa">Casa</option>
              <option value="Apartamento">Apartamento</option>
              <option value="Terreno">Terreno</option>
              <option value="Local Comercial">Local Comercial</option>
            </select>
          </div>

          {/* Campo: Barrio */}
          <div className={styles.formGroup}>
            <label htmlFor="Barrio">
              Barrio: <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              id="Barrio"
              name="Barrio"
              value={additionalData.Barrio}
              onChange={(e) => handleChange('Barrio', e.target.value)}
              required
            />
          </div>

          {/* Campo: Departamento */}
          <div className={styles.formGroup}>
            <label htmlFor="Departamento">
              Departamento: <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              id="Departamento"
              name="Departamento"
              value={additionalData.Departamento}
              onChange={(e) => handleChange('Departamento', e.target.value)}
              required
            />
          </div>

          {/* Campo: Padrón */}
          <div className={styles.formGroup}>
            <label htmlFor="Padron">
              Padrón: <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              id="Padron"
              name="Padron"
              value={additionalData.Padron}
              onChange={(e) => handleChange('Padron', e.target.value)}
              required
            />
          </div>

          {/* Campo: Fecha */}
          <div className={styles.formGroup}>
            <label htmlFor="Fecha">
              Fecha: <span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              id="Fecha"
              name="Fecha"
              value={additionalData.Fecha}
              onChange={(e) => handleChange('Fecha', e.target.value)}
              required
            />
          </div>

          {/* Campo: Superficie Construida */}
          <div className={styles.formGroup}>
            <label htmlFor="SuperficieConstruida">Superficie Construida (m²):</label>
            <input
              type="number"
              id="SuperficieConstruida"
              name="SuperficieConstruida"
              value={additionalData.SuperficieConstruida}
              onChange={(e) => handleChange('SuperficieConstruida', e.target.value)}
              min="0"
            />
          </div>

          {/* Campo: Dormitorios */}
          <div className={styles.formGroup}>
            <label htmlFor="Dormitorios">Dormitorios:</label>
            <input
              type="number"
              id="Dormitorios"
              name="Dormitorios"
              value={additionalData.Dormitorios}
              onChange={(e) => handleChange('Dormitorios', e.target.value)}
              min="0"
            />
          </div>

          {/* Campo: Baños */}
          <div className={styles.formGroup}>
            <label htmlFor="Banos">Baños:</label>
            <input
              type="number"
              id="Banos"
              name="Banos"
              value={additionalData.Banos}
              onChange={(e) => handleChange('Banos', e.target.value)}
              min="0"
            />
          </div>

          {/* Campo: Garaje */}
          <div className={styles.formGroup}>
            <label htmlFor="Garaje">Garaje:</label>
            <select
              id="Garaje"
              name="Garaje"
              value={additionalData.Garaje}
              onChange={(e) => handleChange('Garaje', e.target.value)}
            >
              <option value="">Seleccione</option>
              <option value="Sí">Sí</option>
              <option value="No">No</option>
            </select>
          </div>

          {/* Campo: Número de Piso */}
          <div className={styles.formGroup}>
            <label htmlFor="NumeroDePiso">Número de Piso:</label>
            <input
              type="number"
              id="NumeroDePiso"
              name="NumeroDePiso"
              value={additionalData.NumeroDePiso}
              onChange={(e) => handleChange('NumeroDePiso', e.target.value)}
              min="0"
            />
          </div>

          {/* Campo: Disposición */}
          <div className={styles.formGroup}>
            <label htmlFor="Disposicion">Disposición:</label>
            <select
              id="Disposicion"
              name="Disposicion"
              value={additionalData.Disposicion}
              onChange={(e) => handleChange('Disposicion', e.target.value)}
            >
              <option value="">Seleccione</option>
              <option value="Frente">Frente</option>
              <option value="Contrafrente">Contrafrente</option>
              <option value="Lateral">Lateral</option>
            </select>
          </div>

          {/* Campo: Gastos Comunes */}
          <div className={styles.formGroup}>
            <label htmlFor="GastosComunes">Gastos Comunes:</label>
            <input
              type="number"
              id="GastosComunes"
              name="GastosComunes"
              value={additionalData.GastosComunes}
              onChange={(e) => handleChange('GastosComunes', e.target.value)}
              min="0"
            />
          </div>

          {/* Campo: Cantidad de Plantas */}
          <div className={styles.formGroup}>
            <label htmlFor="CantidadDePlantas">Cantidad de Plantas:</label>
            <input
              type="number"
              id="CantidadDePlantas"
              name="CantidadDePlantas"
              value={additionalData.CantidadDePlantas}
              onChange={(e) => handleChange('CantidadDePlantas', e.target.value)}
              min="0"
            />
          </div>

          {/* Campo: Estado de Conservación */}
          <div className={styles.formGroup}>
            <label htmlFor="EstadoDeConservacion">Estado de Conservación:</label>
            <select
              id="EstadoDeConservacion"
              name="EstadoDeConservacion"
              value={additionalData.EstadoDeConservacion}
              onChange={(e) => handleChange('EstadoDeConservacion', e.target.value)}
            >
              <option value="">Seleccione</option>
              <option value="Excelente">Excelente</option>
              <option value="Bueno">Bueno</option>
              <option value="Regular">Regular</option>
              <option value="Malo">Malo</option>
            </select>
          </div>

          {/* Campo: Luminosidad */}
          <div className={styles.formGroup}>
            <label htmlFor="Luminosidad">Luminosidad:</label>
            <select
              id="Luminosidad"
              name="Luminosidad"
              value={additionalData.Luminosidad}
              onChange={(e) => handleChange('Luminosidad', e.target.value)}
            >
              <option value="">Seleccione</option>
              <option value="Alta">Alta</option>
              <option value="Media">Media</option>
              <option value="Baja">Baja</option>
            </select>
          </div>

          {/* Campo: Descripción */}
          <div className={`${styles.formGroup} ${styles.formGroupFull}`}>
            <label htmlFor="Descripcion">
              Descripción: <span className={styles.required}>*</span>
            </label>
            <textarea
              id="Descripcion"
              name="Descripcion"
              value={additionalData.Descripcion}
              onChange={(e) => handleChange('Descripcion', e.target.value)}
              required
              minLength={150} // Campo obligatorio con mínimo de 150 caracteres
            />
          </div>

          {/* Campo: Observaciones */}
          <div className={`${styles.formGroup} ${styles.formGroupFull}`}>
            <label htmlFor="Observaciones">Observaciones:</label>
            <textarea
              id="Observaciones"
              name="Observaciones"
              value={additionalData.Observaciones}
              onChange={(e) => handleChange('Observaciones', e.target.value)}
            />
          </div>

          {/* Campos de Moneda: Valor Máximo, Valor Estimado, Valor Mínimo */}
          <div className={styles.formGroup}>
            <label htmlFor="ValorMaximo">Valor Máximo:</label>
            <CurrencyInput
              id="ValorMaximo"
              name="ValorMaximo"
              placeholder="U$S152.540"
              value={additionalData.ValorMaximo}
              decimalsLimit={3}
              fixedDecimalLength={3}
              onValueChange={(value, name, values) => handleChange(name, value)}
              prefix="U$S "
              className={getCurrencyInputClass('ValorMaximo')}
              decimalSeparator="."
              groupSeparator=","
              intlConfig={{ locale: 'es-AR', currency: 'ARS' }}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="ValorEstimado">Valor Estimado:</label>
            <CurrencyInput
              id="ValorEstimado"
              name="ValorEstimado"
              placeholder="U$S142.810"
              value={additionalData.ValorEstimado}
              decimalsLimit={3}
              fixedDecimalLength={3}
              onValueChange={(value, name, values) => handleChange(name, value)}
              prefix="U$S "
              className={getCurrencyInputClass('ValorEstimado')}
              decimalSeparator="."
              groupSeparator=","
              intlConfig={{ locale: 'es-AR', currency: 'ARS' }}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="ValorMinimo">Valor Mínimo:</label>
            <CurrencyInput
              id="ValorMinimo"
              name="ValorMinimo"
              placeholder="U$S133.081"
              value={additionalData.ValorMinimo}
              decimalsLimit={3}
              fixedDecimalLength={3}
              onValueChange={(value, name, values) => handleChange(name, value)}
              prefix="U$S "
              className={getCurrencyInputClass('ValorMinimo')}
              decimalSeparator="."
              groupSeparator=","
              intlConfig={{ locale: 'es-AR', currency: 'ARS' }}
              required
            />
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.submitButton}>
            Generar PDF
          </button>
          <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
            Cancelar
          </button>
        </div>
      </form>
    </Modal>
  );
};

AdditionalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    ValorMaximo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ValorEstimado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ValorMinimo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

AdditionalForm.defaultProps = {
  initialValues: {
    ValorMaximo: '',
    ValorEstimado: '',
    ValorMinimo: '',
  },
};

export default AdditionalForm;
