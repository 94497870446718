import React, { useState, useEffect } from 'react';
import styles from './Form.module.css';
import InfoPopover from './InfoPopover'; // Importar el componente InfoPopover

function Form({ onSubmit }) {
  const getInitialFormData = () => ({
    antecedentes: [
      { x: 1, m2: '', construccion: '', ubicacion: '' },
      { x: 1, m2: '', construccion: '', ubicacion: '' },
      { x: 1, m2: '', construccion: '', ubicacion: '' },
      { x: 1, m2: '', construccion: '', ubicacion: '' },
    ],
    valoresVenta: ['', '', '', ''],
    bienProblema: { m2: '', construccion: '', ubicacion: '' },
  });

  const [statusMessage, setStatusMessage] = useState(null);
  const [formData, setFormData] = useState(getInitialFormData());
  const [isComplete, setIsComplete] = useState(false);

  // Recuperar los datos del formulario desde LocalStorage o un archivo cargado
  useEffect(() => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Verificar si el formulario está completo cada vez que formData cambia
  useEffect(() => {
    setIsComplete(isFormComplete());
  }, [formData]);

  // Función para validar si todos los campos están completos
  const isFormComplete = () => {
    // Verificar campos de antecedentes
    for (let antecedente of formData.antecedentes) {
      if (
        antecedente.m2.trim() === '' ||
        antecedente.construccion.trim() === '' ||
        antecedente.ubicacion.trim() === ''
      ) {
        return false;
      }
    }

    // Verificar valores de venta
    for (let valor of formData.valoresVenta) {
      if (valor.trim() === '') {
        return false;
      }
    }

    // Verificar campos del bien problema
    if (
      formData.bienProblema.m2.trim() === '' ||
      formData.bienProblema.construccion.trim() === '' ||
      formData.bienProblema.ubicacion.trim() === ''
    ) {
      return false;
    }

    return true;
  };

  // Función para guardar los datos como un archivo JSON
  const handleSaveProgress = () => {
    // Validar si el formulario está completo
    if (!isFormComplete()) {
      setStatusMessage('Por favor, completa todos los campos antes de guardar el progreso.');
      return;
    }

    const data = JSON.stringify(formData, null, 2); // Convertir a JSON con formato
    const blob = new Blob([data], { type: 'application/json' }); // Crear un blob
    const link = document.createElement('a'); // Crear un enlace de descarga
    link.href = URL.createObjectURL(blob); // Crear un objeto URL para el blob
    link.download = 'progreso_tasacion.json'; // Definir el nombre del archivo
    link.click(); // Simular un clic para descargar el archivo

    setStatusMessage('Progreso guardado exitosamente.');
  };

  // Función para cargar los datos desde un archivo JSON
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Obtener el archivo
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const parsedData = JSON.parse(reader.result);
          // Validar que la estructura del JSON sea correcta
          if (
            parsedData.antecedentes &&
            parsedData.valoresVenta &&
            parsedData.bienProblema
          ) {
            setFormData(parsedData); // Establecer los datos del formulario con el archivo cargado
            setStatusMessage('Datos cargados correctamente.');
          } else {
            throw new Error('Estructura de datos inválida.');
          }
        } catch (error) {
          setStatusMessage('Error al cargar el archivo. Asegúrate de que el archivo sea válido.');
        }
      };
      reader.readAsText(file); // Leer el archivo como texto
    }
  };

  // Manejar cambios en los campos de entrada para campos controlados
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Verificar si es un campo de "Valor de Venta"
    const valorVentaMatch = name.match(/^valor_de_venta_antecedente-(\d+)$/);
    if (valorVentaMatch) {
      const index = parseInt(valorVentaMatch[1], 10) - 1;

      setFormData((prevData) => {
        const newValoresVenta = [...prevData.valoresVenta];
        newValoresVenta[index] = value;
        return { ...prevData, valoresVenta: newValoresVenta };
      });
      return;
    }

    // Verificar si es un campo de antecedentes específico
    const antecedenteMatch = name.match(/^(m2|construccion|ubicacion)_antecedente-(\d+)$/);
    if (antecedenteMatch) {
      const field = antecedenteMatch[1];
      const index = parseInt(antecedenteMatch[2], 10) - 1;

      setFormData((prevData) => {
        const newAntecedentes = [...prevData.antecedentes];
        newAntecedentes[index] = {
          ...newAntecedentes[index],
          [field]: value,
        };
        return { ...prevData, antecedentes: newAntecedentes };
      });
      return;
    }

    // Verificar si es un campo del bien problema
    if (['m2', 'construccion', 'ubicacion'].includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        bienProblema: {
          ...prevData.bienProblema,
          [name]: value,
        },
      }));
      return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar si el formulario está completo
    if (!isFormComplete()) {
      setStatusMessage('Por favor, completa todos los campos antes de realizar la tasación.');
      return;
    }

    // Convertir los valores de los campos a números donde corresponda
    const antecedentes = formData.antecedentes.map((antecedente) => ({
      x: 1,
      m2: parseFloat(antecedente.m2),
      construccion: parseFloat(antecedente.construccion),
      ubicacion: parseFloat(antecedente.ubicacion),
    }));

    const valoresVenta = formData.valoresVenta.map((valor) => parseFloat(valor));

    const bienProblema = {
      m2: parseFloat(formData.bienProblema.m2),
      construccion: parseFloat(formData.bienProblema.construccion),
      ubicacion: parseFloat(formData.bienProblema.ubicacion),
    };

    const data = {
      antecedentes,
      valoresVenta,
      bienProblema,
    };

    // Guardar los datos en LocalStorage
    localStorage.setItem('formData', JSON.stringify(formData));

    try {
      await onSubmit(data); // Enviar los datos al servidor o continuar con el flujo
      setStatusMessage('Tasación realizada exitosamente.');
    } catch (error) {
      setStatusMessage(`Error: ${error.message}`);
    }
  };

  // Función para limpiar el formulario y borrar datos de LocalStorage
  const handleRefresh = () => {
    // Borra los datos de LocalStorage
    localStorage.removeItem('formData');
    // Restablece el formulario a su estado inicial
    setFormData(getInitialFormData());
    setStatusMessage('Formulario refrescado y datos de LocalStorage eliminados.');
  };

  return (
    <div className={styles.formContainer}>
      {/* Contenedor para los botones superiores con fondo diferente */}
      <div className={styles.topButtonsContainer}>
        <div className={styles.topLeftButtons}>
          {/* Botón de Guardar Progreso con tooltip */}
          <button
            type="button"
            onClick={handleSaveProgress}
            className={`${styles.iconButton} ${styles.tooltip}`}
            disabled={!isComplete}
            aria-label="Guardar Progreso"
          >
            <i className="fas fa-save"></i>
            <span className={styles.tooltipText}>Guardar Progreso</span>
          </button>

          {/* Label para Retomar Tasación con tooltip */}
          <label htmlFor="fileInput" className={`${styles.iconButton} ${styles.tooltip}`} aria-label="Retomar Tasación">
            <i className="fas fa-upload"></i>
            <span className={styles.tooltipText}>Retomar Tasación</span>
          </label>
          <input
            type="file"
            id="fileInput"
            accept=".json"
            onChange={handleFileChange}
            className={styles.fileInput}
          />
        </div>

        {/* Botón de refrescar en la esquina superior derecha con tooltip */}
        <button
          type="button"
          onClick={handleRefresh}
          className={`${styles.refreshButton} ${styles.tooltip}`}
          aria-label="Refrescar Formulario"
        >
          <i className="fas fa-sync-alt"></i>
          <span className={styles.tooltipText}>Refrescar Formulario</span>
        </button>
      </div>

      {/* Resto del formulario */}
      <form onSubmit={handleSubmit} className={styles.formContent}>
        <div className={styles.sectionHeader}>
          <h2>Antecedentes</h2>
          <InfoPopover
            content="Comparamos tres características usando un orden numérico (1 es el peor, 5 el mejor). Los números no se saltan: si hay un 3, debe haber un 2 y un 1. Los números pueden repetirse.

Consejo: Es ideal usar inmuebles vendidos para la comparación. Si usas propiedades en venta, ajusta su precio para obtener una mejor estimación."
            position="rigth"
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <table>
              <thead>
                <tr>
                  <th>X</th>
                  <th>M2</th>
                  <th>Construcción</th>
                  <th>Ubicación</th>
                </tr>
              </thead>
              <tbody>
                {formData.antecedentes.map((antecedente, index) => (
                  <tr key={index}>
                    <td>1</td>
                    <td>
                      <input
                        type="text"
                        name={`m2_antecedente-${index + 1}`}
                        value={antecedente.m2}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name={`construccion_antecedente-${index + 1}`}
                        value={antecedente.construccion}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name={`ubicacion_antecedente-${index + 1}`}
                        value={antecedente.ubicacion}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Valor de Venta</th>
                </tr>
              </thead>
              <tbody>
                {formData.valoresVenta.map((valor, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        name={`valor_de_venta_antecedente-${index + 1}`}
                        value={valor}
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.sectionHeader}>
          <h2>Datos del bien problema</h2>
          <InfoPopover
            content="Este inmueble también debe incluirse en el orden numérico que hemos utilizado para los antecedentes. Es parte de la comparación y se califica en el mismo rango de 1 a 5."
            position="rigth"
          />
        </div>

        <table className={styles.bienProblemaTable}>
          <thead>
            <tr>
              <th>M2</th>
              <th>Construcción</th>
              <th>Ubicación</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  name="m2"
                  value={formData.bienProblema.m2}
                  onChange={handleInputChange}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  name="construccion"
                  value={formData.bienProblema.construccion}
                  onChange={handleInputChange}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  name="ubicacion"
                  value={formData.bienProblema.ubicacion}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className={styles.submitContainer}>
          <button type="submit" className={styles.submitButton}>
            Realizar Tasación
          </button>
        </div>

        {statusMessage && <p>{String(statusMessage)}</p>}
      </form>
    </div>
  );
}

export default Form;
