// src/components/ProcesoDatos.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProcesoDatos.module.css';
import { formatCurrency, formatNumberTwoDecimals, formatPercentage } from '../utils/formatCurrency'; // Ruta corregida
import InfoPopover from './InfoPopover'; // Importar el componente InfoPopover

const ProcesoDatos = ({ analysis }) => {
  const {
    antecedentes_inverse,
    K,
    error,
    coeficientes_ajuste,
    valores_ajustados,
    valor_estimado,
    desviacion_estandar,
    incertidumbre,
    valor_minimo,
    valor_maximo,
  } = analysis;

  // Función para renderizar matrices con indicador de si es matriz K
  const renderMatrix = (matrix, isK = false) => (
    <div className={styles.matrixContainer}>
      {matrix.map((row, rowIndex) => (
        <div className={styles.matrixRow} key={rowIndex}>
          {row.map((value, colIndex) => (
            <div
              className={`${styles.matrixValue} ${
                isK && value < 0 ? styles.matrixValueNegative : ''
              }`}
              key={colIndex}
            >
              {typeof value === 'number' ? value.toFixed(2) : value}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  // Función para renderizar coeficientes
  const renderCoeficientes = (coef) => (
    coef ? (
      <ul className={styles.procesoDatosList}>
        <li>C_m2: {coef.C_m2 !== null ? coef.C_m2.toFixed(2) : 'N/A'}</li>
        <li>C_construcciones: {coef.C_construcciones !== null ? coef.C_construcciones.toFixed(2) : 'N/A'}</li>
        <li>C_ubicacion: {coef.C_ubicacion !== null ? coef.C_ubicacion.toFixed(2) : 'N/A'}</li>
        <li>C: {coef.C !== null ? coef.C.toFixed(2) : 'N/A'}</li>
      </ul>
    ) : <p>Datos de coeficientes no disponibles</p>
  );

  return (
    <div className={styles.procesoDatosContainer}>
      <h2>Proceso de Datos</h2>

      {/* Mostrar error si existe */}
      {error && (
        <div className={styles.matrixBox}>
          <h3>Error:</h3>
          <p>{error}</p>
        </div>
      )}

      {/* Mostrar Matriz Inversa de Antecedentes */}
      <div className={styles.matrixBox}>
        <div className={styles.sectionHeader}>
          <h3>Matriz Inversa de Antecedentes</h3>
          <InfoPopover
            content="Se calcula para resolver sistemas de ecuaciones lineales y encontrar los coeficientes que relacionan las características de las propiedades con sus valores de venta, facilitando comparaciones más precisas."
            position="right"
          />
        </div>
        {antecedentes_inverse && antecedentes_inverse.length > 0 ? (
          renderMatrix(antecedentes_inverse)
        ) : (
          <p>No hay datos disponibles para la matriz inversa.</p>
        )}
      </div>

      {/* Mostrar Matriz K */}
      <div className={styles.matrixBox}>
        <div className={styles.sectionHeader}>
          <h3>Matriz K</h3>
          <InfoPopover
            content="Contiene coeficientes que indican cuánto influye cada característica (metros cuadrados, construcción, ubicación) en el valor de la propiedad. Valores negativos en K pueden indicar inconsistencias en los datos y deben ser revisados."
            position="right"
          />
        </div>
        {K && K.length > 0 ? (
          renderMatrix(K, true) // Indicamos que es la matriz K para aplicar estilos negativos
        ) : (
          <p>No hay datos disponibles para K.</p>
        )}
      </div>

      {/* Mostrar Coeficientes de Ajuste */}
      <div className={styles.matrixBox}>
        <div className={styles.sectionHeader}>
          <h3>Coeficientes de Ajuste</h3>
          <InfoPopover
            content="Calibran cada propiedad antecedente para reflejar las diferencias en metros cuadrados, construcción y ubicación respecto al bien problema, estimando cómo cambiaría su valor con las mismas características."
            position="right"
          />
        </div>
        {coeficientes_ajuste ? (
          <div className={styles.coeficientesGrid}>
            <div className={styles.coeficiente}>
              <h4>C1</h4>
              {renderCoeficientes(coeficientes_ajuste.C1)}
            </div>
            <div className={styles.coeficiente}>
              <h4>C2</h4>
              {renderCoeficientes(coeficientes_ajuste.C2)}
            </div>
            <div className={styles.coeficiente}>
              <h4>C3</h4>
              {renderCoeficientes(coeficientes_ajuste.C3)}
            </div>
            <div className={styles.coeficiente}>
              <h4>C4</h4>
              {renderCoeficientes(coeficientes_ajuste.C4)}
            </div>
          </div>
        ) : (
          <p>No hay datos de coeficientes de ajuste disponibles.</p>
        )}
      </div>

      {/* Mostrar Valores Ajustados */}
      <div className={styles.matrixBox}>
        <div className={styles.sectionHeader}>
          <h3>Valores Ajustados</h3>
          <InfoPopover
            content="Representan el valor de cada propiedad antecedente después de aplicar los coeficientes de ajuste, reflejando su valor estimado si tuviera las mismas características que el bien problema."
            position="right"
          />
        </div>
        {valores_ajustados ? (
          <div className={styles.valoresAjustadosGrid}>
            <div className={styles.valorAjustado}>
              <h4>V1</h4>
              <p>{formatCurrency(valores_ajustados.B1)}</p>
            </div>
            <div className={styles.valorAjustado}>
              <h4>V2</h4>
              <p>{formatCurrency(valores_ajustados.B2)}</p>
            </div>
            <div className={styles.valorAjustado}>
              <h4>V3</h4>
              <p>{formatCurrency(valores_ajustados.B3)}</p>
            </div>
            <div className={styles.valorAjustado}>
              <h4>V4</h4>
              <p>{formatCurrency(valores_ajustados.B4)}</p>
            </div>
          </div>
        ) : (
          <p>No hay datos de valores ajustados disponibles.</p>
        )}
      </div>

      {/* Mostrar Resumen de Tasación */}
      <div className={styles.matrixBox}>
        <div className={styles.sectionHeader}>
          <h3>Resumen de Tasación</h3>
          <InfoPopover
            content="El Resumen de Tasación presenta el valor probable de la propiedad (promedio de los valores ajustados), la desviación estándar que mide la variabilidad de las estimaciones, y la incertidumbre que indica la confianza en el resultado. También incluye los valores mínimo y máximo para ofrecer un rango probable del valor."
            position="right"
          />
        </div>
        <div className={styles.resumenTasacionGrid}>
          <div className={styles.resumenTasacionItem}>
            <h4>Valor Probable</h4>
            <p>{formatCurrency(valor_estimado)}</p>
          </div>
          <div className={styles.resumenTasacionItem}>
            <h4>Desviación Estándar</h4>
            <p>{formatNumberTwoDecimals(desviacion_estandar)}</p>
          </div>
          <div className={styles.resumenTasacionItem}>
            <h4>Incertidumbre</h4>
            <p>{formatPercentage(incertidumbre)}</p>
          </div>
          <div className={styles.resumenTasacionItem}>
            <h4>Valor Mínimo</h4>
            <p>{formatCurrency(valor_minimo)}</p>
          </div>
          <div className={styles.resumenTasacionItem}>
            <h4>Valor Máximo</h4>
            <p>{formatCurrency(valor_maximo)}</p>
          </div>
          <div className={styles.resumenTasacionItem}>
            <h4>Nivel de Confianza</h4>
            <p>90%</p> {/* Campo por defecto */}
          </div>
        </div>
      </div>
    </div>
  );
};

// Definición de los tipos de props para el componente
ProcesoDatos.propTypes = {
  analysis: PropTypes.shape({
    antecedentes_inverse: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    K: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    error: PropTypes.string,
    coeficientes_ajuste: PropTypes.shape({
      C1: PropTypes.shape({
        C_m2: PropTypes.number,
        C_construcciones: PropTypes.number,
        C_ubicacion: PropTypes.number,
        C: PropTypes.number,
      }),
      C2: PropTypes.shape({
        C_m2: PropTypes.number,
        C_construcciones: PropTypes.number,
        C_ubicacion: PropTypes.number,
        C: PropTypes.number,
      }),
      C3: PropTypes.shape({
        C_m2: PropTypes.number,
        C_construcciones: PropTypes.number,
        C_ubicacion: PropTypes.number,
        C: PropTypes.number,
      }),
      C4: PropTypes.shape({
        C_m2: PropTypes.number,
        C_construcciones: PropTypes.number,
        C_ubicacion: PropTypes.number,
        C: PropTypes.number,
      }),
    }),
    valores_ajustados: PropTypes.shape({
      B1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      B2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      B3: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      B4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    valor_estimado: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    desviacion_estandar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    incertidumbre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    valor_minimo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    valor_maximo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default ProcesoDatos;
