// src/components/Result.js

import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../utils/formatCurrency';
import styles from './Result.module.css'; // Importar el CSS Module correctamente

const Result = ({ valorMinimo, valorEstimado, valorMaximo }) => {
  // Agrega logs para depurar los valores de los props
  console.log('Result Component - Valor Mínimo:', valorMinimo);
  console.log('Result Component - Valor Estimado:', valorEstimado);
  console.log('Result Component - Valor Máximo:', valorMaximo);

  return (
    <div className={styles.resultContainer}>
      <h2>Resultado</h2>
      <div className={styles.resultGrid}>
        <div className={styles.resultItem}>
          <h4>Valor mínimo</h4>
          <p>{formatCurrency(valorMinimo)}</p>
        </div>
        <div className={styles.resultItem}>
          <h4>Valor estimado</h4>
          <p>{formatCurrency(valorEstimado)}</p>
        </div>
        <div className={styles.resultItem}>
          <h4>Valor máximo</h4>
          <p>{formatCurrency(valorMaximo)}</p>
        </div>
      </div>
    </div>
  );
};

Result.propTypes = {
  valorMinimo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  valorEstimado: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  valorMaximo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Result;
